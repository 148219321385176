a:visited {
  color: #8697da;
}

a:link {
  color: #4c67d7;
}

.titleLink {
  color: white !important;
  font-size: 20px;
  font-weight: 700;
  text-decoration: underline;
  font-family: 'Courgette', cursive
}

.titleLinkNoDeco {
  color: white !important;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  font-family: 'Courgette', cursive
}

.link {
  color: white !important;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.landingBackground {
  background-position: center;
  background-blend-mode: darken;
  background-size: cover;
  box-shadow: 5px 5px 5px transparent;
  width: 100%;
  height: 100%;
  position: absolute;
}

.mainGitHubButton {
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  z-index: 1;
  width: 250px;
  height: 50px;
  background: linear-gradient(90deg, #172E4D 55% ,#73A9F3 100%);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer
}

.mainGitHubButtonTitle {
  padding-left: 1em;
  padding-right: 1em;
  font-size: 32px !important;
}

.mainGitHubButtonContainer {
  max-width: 40em !important;
  text-align: left;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  padding-bottom: 360px;
  height: 95vh;
}

.DownloadButtonContainer {
  max-width: 45em !important;
  text-align: left;
  position: relative;
  z-index: 1;
  padding-top: 220px;
  padding-bottom: 360px;
  height: 95vh;
}

.MoulmermanTitleContainer {
  max-width: 45em !important;
  text-align: left;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  height: 95vh;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #08162d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #012d70;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #002359;
}
