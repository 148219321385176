.waterBackground {
    background-position: center;
    background-blend-mode: darken;
    background-size: cover;
    box-shadow: 5px 5px 5px transparent;
    width: 100%;
    height: 100%;
    position: absolute;
}

.waterTitle {
    max-width: 45em !important;
    text-align: center;
    position: relative;
    z-index: 1;
    padding-top: 150px;
    padding-bottom: 360px;
    height: 95vh;
}